import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useCareerPosition } from '../src/contexts/CareerPositionContext'
import { useConfigurator } from '../src/contexts/ConfiguratorContext'
import { useSendContactMessageForm, UseSendContactMessageFormArgs } from '../src/hooks/useSendContactMessageForm'
import { useTranslate } from '../src/hooks/useTranslate'
import { BarelyVisibleTitle } from './BarelyVisibleTitle'
import { Button } from './Button'
import {
	FileUploadInput,
	FileUploadInputState,
	FileUploadInputStatus,
} from './FileUploadInput'
import { GdprDisclaimer } from './GdprDisclaimer'
import { Input } from './Input'
import { Recaptcha } from './Recaptcha'
import { Spacer } from './Spacer'

export interface ContactFormProps {
	title?: React.ReactNode
	note?: React.ReactNode
	allowFileInput?: boolean
	requireCity?: boolean
	requireFormFields?: boolean 
	formTargetEmail? :string
}

export const ContactForm: React.FunctionComponent<ContactFormProps> = ({
	title,
	note,
	allowFileInput = false,
	requireCity = false,
	requireFormFields = true,
	formTargetEmail = undefined
}) => {

	const translate = useTranslate()
	const router = useRouter()
	const isProject = router.asPath.endsWith('/projekt-zdarma')
	const { isPosition: isCareerPosition, message } = useCareerPosition()
	const { isConfigurator, staticMessageContent: configuratorStaticMessageContent, subject } = useConfigurator()

	const type = isCareerPosition
	? ('career' as const)
	: isConfigurator
	? ('configurator' as const)
	: isProject
	? ('project' as const)
	: ('contact' as const);
	const staticMessageContent = (type === 'configurator') ? configuratorStaticMessageContent : undefined;

	let contactFormArgs = useMemo(
		() => ({
			type: type,
			staticMessageContent: staticMessageContent,
			providedSubject: (type === 'configurator') ? subject : undefined,
			providedEmail: formTargetEmail ?? undefined
		} as UseSendContactMessageFormArgs),
		[isCareerPosition, isProject, isConfigurator, formTargetEmail],
	)
	const { sent, error, register, submit, setValue } =
		useSendContactMessageForm(contactFormArgs)
	useEffect(() => {
		register('fileUrl')
	}, [register])

	const [loading, setLoading] = useState(false)
	const [buttonDisabled, setButtonDisabled] = useState(false)

	const uploadHandler = useCallback(
		(state: FileUploadInputState) => {
			setButtonDisabled(state.status === FileUploadInputStatus.processing)
			if (state.status === FileUploadInputStatus.success) {
				setValue('fileUrl', state.publicUrl)
			}
			if (state.status === FileUploadInputStatus.error) {
				toast.error('Došlo k chybě při nahrávání souboru')
			}
		},
		[setValue],
	)

	React.useEffect(() => {
		if (sent) {
			toast.success('Zpráva byla odeslána.')
		}
	}, [sent])

	React.useEffect(() => {
		if (error) {
			toast.error(error)
		}
	}, [error])

	return (
		<form className="contactForm" onSubmit={async (e) => {
			setLoading(true);
			await submit(e);
			setLoading(false);
		}}>
			<input type="hidden" name="validation" value={requireFormFields ? 1 : 0}></input>
			{(title || note) && (
				<div className="contactForm-header">
					{title && (
						<>
							<BarelyVisibleTitle level={2}>{title}</BarelyVisibleTitle>
							<Spacer initial={16} />
						</>
					)}
					{note && (
						<>
							<div>{note}</div>
							<Spacer initial={10} />
						</>
					)}
				</div>
			)}
			<div className="contactForm-field">
				<Input
					multiline
					label={translate('contactForm-message-label')}
					ref={register}
					name="message"
					defaultValue={message} 
				/>
			</div>
			<div className="contactForm-field">
				<Input
					label={translate('contactForm-name-label')}
					autoComplete="name"
					required={requireFormFields}
					name="name"
					ref={register}
				/>
			</div>
			<div className="contactForm-field">
				<Input
					label={translate('contactForm-email-label')}
					autoComplete="email"
					required={requireFormFields}
					name="email"
					ref={register}
				/>
			</div>
			<div className="contactForm-field">
				<Input
					label={translate('contactForm-telephone-label')}
					autoComplete="tel"
					required={requireFormFields}
					name="phone"
					ref={register}
				/>
			</div>
			{requireCity && (
				<div className="contactForm-group">
					<div className="contactForm-field">
						<Input
							label={translate('contactForm-city-label')}
							autoComplete="address-level2"
							required={requireFormFields}
							name="city"
							ref={register}
						/>
					</div>
					<div className="contactForm-field">
						<Input
							label={translate('contactForm-zip-label')}
							autoComplete="postal-code"
							required={requireFormFields}
							name="zip"
							ref={register}
						/>
					</div>
				</div>
			)}
			{allowFileInput && (
				<div className="contactForm-field">
					<FileUploadInput
						label={
							isCareerPosition
								? translate('contactForm-file-cv-label')
								: translate('contactForm-file-general-label')
						}
						name={'fileUrl'}
						onStateChange={uploadHandler}
					/>
				</div>
			)}
			<div className="contactForm-field">
				<GdprDisclaimer />
			</div>
			<div className="contactForm-recaptcha">
				<Recaptcha register={register} setValue={setValue} />
			</div>
			<div className="contactForm-submit">
				<Button type="submit" block disabled={buttonDisabled} loading={loading}>
					{translate('contactForm-submit-label')}
				</Button>
			</div>
		</form>
	)
}
