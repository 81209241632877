import React from 'react'

export interface GardenProps {
	title: string | React.ReactNode
}

export const Garden: React.FunctionComponent<GardenProps> = ({
	title,
	children,
}) => {
	return (
		<section className="garden">
			<h2 className="garden-title">{title}</h2>
			<div className="garden-content">{children}</div>
		</section>
	)
}
